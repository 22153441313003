import React, { useState, Fragment } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import aperto from "../../assets/img/donazioni/free.png"
import chiuso from "../../assets/img/donazioni/block.png"
import Swal from 'sweetalert2'
import { Link, NavLink, useLocation } from "react-router-dom";



function Mappa() {
  return (
    <Container fluid className="project-sectionar">
      <Container>
        <h1 className="project-headings"style={{ color: "black" }}>
           <strong className="purples">Legenda</strong> 
        </h1>
        <p className="project-headings" style={{ color: "black" }}>
          🔵: Zona Bianca
          <br></br>
          🔴: Zona dell'Esercito
          <br></br>
          🟢: Grande Riserva Wapiti 
        </p>
        <li className={"btnmappa"}>
                      <Link to="https://westernlegends.it" className="btn">
                      Torna alla Home
                        </Link>
                      </li>
        </Container>
    </Container>
  );
}

export default Mappa;