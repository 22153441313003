import React, { useState, Fragment } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import mappa from "../../assets/img/donazioni/mappers.png"
import aperto from "../../assets/img/donazioni/free.png"
import chiuso from "../../assets/img/donazioni/block.png"
import imgmap from "../../assets/img/icon/logospendente.gif"
import Swal from 'sweetalert2'
import { Link, NavLink, useLocation } from "react-router-dom";



function Mappa() {
  // POSIZIONI RANCH, DA CAMBIARE NOME E POSIZIONE PER OGNI RANCH

  const uno = {
    left: '453px',
    top: "330px",
    position: "relative",
  };

  const due = {
    left: '398px',
    top: "720px",
    position: "relative",
  };
  
  const tre = {
    left: '400px',
    top: "755px",
    position: "relative",
  };
  
  const quattro = {
    left: '515px',
    top: "255px",
    position: "relative",
  };
  
  const cinque = {
    left: '623px',
    top: "292px",
    position: "relative",
  };

  const sei = {
    left: '1000px',
    top: "220px",
    position: "relative",
  };

  const sette = {
    left: '1012px',
    top: "135px",
    position: "relative",
  };

  const otto = {
    left: '630px',
    top: "545px",
    position: "relative",
  };

  const nove = {
    left: '665px',
    top: "595px",
    position: "relative",
  };

  const dieci = {
    left: '1003px',
    top: "125px",
    position: "relative",
  };
  
  const undici = {
    left: '1065px',
    top: "165px",
    position: "relative",
  };

  const dodici = {
    left: '970px',
    top: "320px",
    position: "relative",
  };

  const tredici = {
    left: '940px',
    top: "350px",
    position: "relative",
  };

  const quattordici = {
    left: '715px',
    top: "540px",
    position: "relative",
  };

  const quindici = {
    left: '-20px',
    top: "390px",
    position: "relative",
  };

  const sedici = {
    left: '790px',
    top: "545px",
    position: "relative",
  };

  const diciasette = {
    left: '738px',
    top: "625px",
    position: "relative",
  };

  const diciotto = {
    left: '587px',
    top: "652px",
    position: "relative",
  };

  const dicianove = {
    left: '450px',
    top: "705px",
    position: "relative",
  };

  const venti = {
    left: '376px',
    top: "430px",
    position: "relative",
  };

  const ventuno = {
    left: '-134px',
    top: "360px",
    position: "relative",
  };

  const ventidue = {
    left: '325px',
    top: "785px",
    position: "relative",
  };

  const ventitre = {
    left: '445px',
    top: "422px",
    position: "relative",
  };

  const ventiquattro = {
    left: '447px',
    top: "525px",
    position: "relative",
  };

  const venticinque = {
    left: '278px',
    top: "875px",
    position: "relative",
  };

  const ventisei = {
    left: '287px',
    top: "800px",
    position: "relative",
  };

  const ventisette = {
    left: '-37px',
    top: "260px",
    position: "relative",
  };

  // GIF RANCH, DA CAMBIARE NOME, LINK, TITOLO, TEXT PER OGNI RANCH

  const Foto1 = () =>{
    Swal.fire({
      title: 'Casa Piccola - (01)',
      background: 'rgba(255, 255, 255, 0.9)',
      padding: '0px',
      margin: '0px'
    })
  }

  const Foto2 = () =>{
    Swal.fire({
      title: 'Casa Piccola - (02)',
      background: 'rgba(255, 255, 255, 0.9)',
      padding: '0px',
      margin: '0px'
    })
  }
  
  const Foto3 = () =>{
    Swal.fire({
      title: 'Casa Piccola - (03)',
      background: 'rgba(255, 255, 255, 0.9)',
      padding: '0px',
      margin: '0px'
    })
  }
  
  const Foto4 = () =>{
    Swal.fire({
      title: 'Casa Piccola - (04)',
      background: 'rgba(255, 255, 255, 0.9)',
      padding: '0px',
      margin: '0px'
    })
  }
  
  const Foto5 = () =>{
    Swal.fire({
      title: 'Casa Piccola - (05)',
      background: 'rgba(255, 255, 255, 0.9)',
      padding: '0px',
      margin: '0px'
    })
  }

  const Foto6 = () =>{
    Swal.fire({
      title: 'Casa Piccola - (06)',
      background: 'rgba(255, 255, 255, 0.9)',
      padding: '0px',
      margin: '0px'
    })
  }
  
  const Foto7 = () =>{
    Swal.fire({
      title: 'Casa Piccola - (07)',
      background: 'rgba(255, 255, 255, 0.9)',
      padding: '0px',
      margin: '0px'
    })
  }
  
  const Foto8 = () =>{
    Swal.fire({
      title: 'Casa Piccola - (08)',
      background: 'rgba(255, 255, 255, 0.9)',
      padding: '0px',
      margin: '0px'
    })
  }

  const Foto9 = () =>{
    Swal.fire({
      title: 'Casa Piccola - (09)',
      background: 'rgba(255, 255, 255, 0.9)',
      padding: '0px',
      margin: '0px'
    })
  }

  const Foto10 = () =>{
    Swal.fire({

      title: 'Casa Piccola - (10)',
      background: 'rgba(255, 255, 255, 0.9)',
      padding: '0px',
      margin: '0px'
    })
  }

  const Foto11 = () =>{
    Swal.fire({
      title: 'Casa Piccola - (11)',
      background: 'rgba(255, 255, 255, 0.9)',
      padding: '0px',
      margin: '0px'
    })
  }
  
  const Foto12 = () =>{
    Swal.fire({
      title: 'Casa Piccola - (12)',
      background: 'rgba(255, 255, 255, 0.9)',
      padding: '0px',
      margin: '0px'
    })
  }

  const Foto13 = () =>{
    Swal.fire({
      title: 'Casa Piccola - (13)',
      background: 'rgba(255, 255, 255, 0.9)',
      padding: '0px',
      margin: '0px'
    })
  }
  
  const Foto14 = () =>{
    Swal.fire({
      title: 'Casa Piccola - (14)',
      background: 'rgba(255, 255, 255, 0.9)',
      padding: '0px',
      margin: '0px'
    })
  }

  const Foto15 = () =>{
    Swal.fire({
      title: 'Casa Piccola - (15)',
      background: 'rgba(255, 255, 255, 0.9)',
      padding: '0px',
      margin: '0px'
    })
  }

  const Foto16 = () =>{
    Swal.fire({
      title: 'Casa Piccola - (16)',
      background: 'rgba(255, 255, 255, 0.9)',
      padding: '0px',
      margin: '0px'
    })
  }

  const Foto17 = () =>{
    Swal.fire({
      title: 'Casa Piccola - (17)',
      background: 'rgba(255, 255, 255, 0.9)',
      padding: '0px',
      margin: '0px'
    })
  }

  const Foto18 = () =>{
    Swal.fire({
      title: 'Casa Piccola - (18)',
      background: 'rgba(255, 255, 255, 0.9)',
      padding: '0px',
      margin: '0px'
    })
  }

  const Foto19 = () =>{
    Swal.fire({
      title: 'Casa Grande - (19)',
      background: 'rgba(255, 255, 255, 0.9)',
      padding: '0px',
      margin: '0px'
    })
  }

  const Foto20 = () =>{
    Swal.fire({
      title: 'Casa Piccola - (20)',
      background: 'rgba(255, 255, 255, 0.9)',
      padding: '0px',
      margin: '0px'
    })
  }

  const Foto21 = () =>{
    Swal.fire({
      title: 'Casa Media - (21)',
      background: 'rgba(255, 255, 255, 0.9)',
      padding: '0px',
      margin: '0px'
    })
  }

  const Foto22 = () =>{
    Swal.fire({
      imageUrl: 'https://cdn.discordapp.com/attachments/953306788986507275/1220331185927098450/Braithwaite.gif?ex=660e8cfb&is=65fc17fb&hm=f34c795b16998f992fae307639fffee2e73ecd0c91fab9c7599ef5212e20acf7&',
      imageAlt: 'Custom image',
      title: 'Casa Piccola - (22)',
      background: 'rgba(255, 255, 255, 0.9)',
      padding: '0px',
      margin: '0px'
    })
  }

  const Foto23 = () =>{
    Swal.fire({
      title: 'Casa Piccola - (23)',
      background: 'rgba(255, 255, 255, 0.9)',
      padding: '0px',
      margin: '0px'
    })
  }

  const Foto24 = () =>{
    Swal.fire({
      title: 'Casa Piccola - (24)',
      background: 'rgba(255, 255, 255, 0.9)',
      padding: '0px',
      margin: '0px'
    })
  }

  const Foto25 = () =>{
    Swal.fire({
      title: 'Casa Grande - (25)',
      background: 'rgba(255, 255, 255, 0.9)',
      padding: '0px',
      margin: '0px'
    })
  }

  const Foto26 = () =>{
    Swal.fire({
      title: 'Casa Piccola - (26)',
      background: 'rgba(255, 255, 255, 0.9)',
      padding: '0px',
      margin: '0px'
    })
  }

  const Foto27 = () =>{
    Swal.fire({
      title: 'Casa Piccola - (26)',
      background: 'rgba(255, 255, 255, 0.9)',
      padding: '0px',
      margin: '0px'
    })
  }

  
  return (
    <Container fluid className="project-sectiona">
      <Container>
        <h1 className="project-headings"style={{ color: "black" }}>
          Le <strong className="purples">Case</strong> Disponibili
        </h1>
        <p className="project-headings" style={{ color: "black" }}>
          Potrai Cliccare e Visionare ogni Casa presente sulla Mappa.
        <p className="project-testo" style={{ color: "black" }}> 
          La Mappa potra essere visualizzata perfettamente solo su schermi a 16:9</p>
        </p>
        <li>
        
        <img style={uno} onClick={Foto1} variant="separate2-button" src={aperto} class="bottone2-img"/>   
        <img style={due} onClick={Foto2} variant="separate2-button" src={aperto} class="bottone2-img"/>
        <img style={tre} onClick={Foto3} variant="separate2-button" src={aperto} class="bottone2-img"/>
        <img style={quattro} onClick={Foto4} variant="separate2-button" src={aperto} class="bottone2-img"/>
        <img style={cinque} onClick={Foto5} variant="separate2-button" src={aperto} class="bottone2-img"/>
        <img style={sei} onClick={Foto6} variant="separate2-button" src={aperto} class="bottone2-img"/>
        <img style={sette} onClick={Foto7} variant="separate2-button" src={aperto} class="bottone2-img"/>
        <img style={otto} onClick={Foto8} variant="separate2-button" src={aperto} class="bottone2-img"/>
        <img style={nove} onClick={Foto9} variant="separate2-button" src={aperto} class="bottone2-img"/>
        <img style={dieci} onClick={Foto10} variant="separate2-button" src={aperto} class="bottone2-img"/>
        <img style={undici} onClick={Foto11} variant="separate2-button" src={aperto} class="bottone2-img"/>
        <img style={dodici} onClick={Foto12} variant="separate2-button" src={aperto} class="bottone2-img"/>
        <img style={tredici} onClick={Foto13} variant="separate2-button" src={aperto} class="bottone2-img"/>
        <img style={quattordici} onClick={Foto14} variant="separate2-button" src={aperto} class="bottone2-img"/>
        <img style={quindici} onClick={Foto15} variant="separate2-button" src={aperto} class="bottone2-img"/>
        <img style={sedici} onClick={Foto16} variant="separate2-button" src={aperto} class="bottone2-img"/>
        <img style={diciasette} onClick={Foto17} variant="separate2-button" src={aperto} class="bottone2-img"/>
        <img style={diciotto} onClick={Foto18} variant="separate2-button" src={aperto} class="bottone2-img"/>
        <img style={dicianove} onClick={Foto19} variant="separate2-button" src={aperto} class="bottone2-img"/>
        <img style={venti} onClick={Foto20} variant="separate2-button" src={aperto} class="bottone2-img"/>
        <img style={ventuno} onClick={Foto21} variant="separate2-button" src={aperto} class="bottone2-img"/>
        <img style={ventidue} onClick={Foto22} variant="separate2-button" src={aperto} class="bottone2-img"/>
        <img style={ventitre} onClick={Foto23} variant="separate2-button" src={aperto} class="bottone2-img"/>
        <img style={ventiquattro} onClick={Foto24} variant="separate2-button" src={aperto} class="bottone2-img"/>
        <img style={venticinque} onClick={Foto25} variant="separate2-button" src={aperto} class="bottone2-img"/>
        <img style={ventisei} onClick={Foto26} variant="separate2-button" src={aperto} class="bottone2-img"/>
        <img style={ventisette} onClick={Foto27} variant="separate2-button" src={aperto} class="bottone2-img"/>


        </li>
        <li className={"btnmapparanch"}>
                      <Link to="https://westernlegends.it" className="btn">
                          Torna alla Home
                        </Link>
                      </li>
        </Container>
    </Container>
  );
}

export default Mappa;