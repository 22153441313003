import React from "react";
import img01 from "../../assets/img/team/team_img01.png";
import img02 from "../../assets/img/team/team_img02.jpg";
import img03 from "../../assets/img/team/team_img03.png";
import img04 from "../../assets/img/team/team_img04.jpg";
import img05 from "../../assets/img/team/team_img05.jpg";
import img06 from "../../assets/img/team/team_img06.png";
import img07 from "../../assets/img/team/team_img07.png";
import img08 from "../../assets/img/team/team_img08.png";
import img09 from "../../assets/img/team/team_img09.png";
import img15 from "../../assets/img/team/team_img15.png";
import img10 from "../../assets/img/team/team_img10.png";

import TeamOneItem from "./TeamOneItem";

const TeamOne = () => {
  const team_members = [
    {
      src: img01,
      name: "TorinoErMejo",
      designation: "Owner",
    },
    {
      src: img02,
      name: "Robizona",
      designation: "Developer",
      description: "Scripter FiveM / RedM",
    },
    {
      src: img03,
      name: "IlPada",
      designation: "Founder",
      description: "Responsabile Staff",
      description2: "Responsabile Side-Quest",
      description3: "Responsabile Criminalita'",
    },
    {
      src: img04,
      name: "TopGun",
      designation: "Founder",
      description: "Responsabile Staff",
      description2: "Responsabile Donazioni",
      description3: "Front-end Developer",
    },
    {
      src: img05,
      name: "Steve ",
      designation: "Coordinatore",
      description: "GameMaster",
      description2: "Responsabile Side-Quest",
      description3: "Responsabile Whitelist",
      description4: "Responsabile Helper",
    },
    {
      src: img06,
      name: "Phanix",
      designation: "Head-Admin",
      description: "Responsabile Attivita'",
      description2: "Aiuto SideQuest",

    },
    {
      src: img07,
      name: "PsychoJoker",
      designation: "Admin",
      description: "",
    },
    {
      src: img08,
      name: "DeadHack",
      designation: "Admin",
      description: "",
    },
    {
      src: img10,
      name: "Trik",
      designation: "Admin",
      description: "",
    },
    {
      src: img09,
      name: "Ciccio",
      designation: "GameMaster",
      description: "Creatore Lore",
    },
    {
      src: img15,
      name: "Mast3r",
      designation: "Developer",
      description: "Back-end Developer",
    },
  ];

  return (
    <section id="staff" className="team-area pt-130">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6">
            <div className="section-title text-center mb-70">
              <span className="sub-title">Lo staff</span>
              <h2 className="title">
                In Nostro <br/> <span>Staff</span>
              </h2>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          {team_members.map((props, index) => (
            <div key={index} className="col-xl-3 col-md-4 col-sm-6">
              <TeamOneItem item={props} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TeamOne;
