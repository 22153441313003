import coo from "../assets/img/images/biscotti.png";
import posthog from 'posthog-js'
import { useState } from 'react'; // new
import { Link, NavLink, useLocation } from "react-router-dom";


function CookieBanner() {
  const [showBanner, setShowBanner] = useState(true); // new
  
  const acceptCookies = () => { 
    posthog.opt_in_capturing();
    setShowBanner(false); // new
  };

  const declineCookies = () => {
    posthog.opt_out_capturing();
    setShowBanner(false); // new
  };

  return (
    <div>
      {showBanner && ( // new
        <div id="cookiePopup">
                <img src={coo} />
          <p>
          Western Legends utilizza i cookie per fornire le necessarie funzionalità del sito Web, migliorare la tua esperienza e analizzare il nostro traffico. Cliccando su Accetta, accetterai i nostri Cookie e le nostre politiche: <a href="/termini&condizioni">Termini & Condizioni</a> </p>
          <button id="acceptCookie" onClick={acceptCookies}>
            Accetta Tutto 
          </button>
          <button id="acceptCookie" onClick={acceptCookies}>
            Accetta Necessari
          </button>
        </div>
      )}
    </div>
  );
}

export default CookieBanner;
