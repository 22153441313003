import React from "react";


const Banner = (props) => {
  return (
    <section className="banner-areas banner-bgs">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="banner-content text-center">
              <h1 className="title">
                TERMINI <span>e</span> CONDIZIONI
              </h1>
            </div>
            <div className="banner-progress-wrap">
              

              <h4 className="title">
              Accettazione dei Termini

              <br></br><br></br><br></br>
1.1 Accedendo e utilizzando Western Legends, dichiari di avere almeno 18 anni di età o di essere un minore con il consenso esplicito dei genitori o di un tutore legale. Accetti inoltre di essere vincolato dai presenti Termini e Condizioni ("i Termini"). Se non accetti tali Termini, ti preghiamo di non utilizzare il Server.
<br></br>
<br></br>
1.1.1 Se sei un minore, dichiari che i tuoi genitori o il tuo tutore legale hanno letto e compreso i presenti Termini e acconsentono al tuo utilizzo del Server in conformità con essi.
<br></br>
<br></br>
1.1.2 Nel caso in cui i tuoi genitori o il tuo tutore legale non siano d'accordo con i presenti Termini, non sei autorizzato a utilizzare il Server.
<br></br>
<br></br>
1.1.3 Ti ricordiamo che il Server potrebbe contenere contenuti adatti solo a un pubblico adulto e che potrebbero essere presenti tematiche mature o violente. Spetta ai genitori o al tutore legale decidere se il Server sia adatto all'utilizzo da parte dei minori.
<br></br>
<br></br>
1.2 I presenti Termini possono essere soggetti a modifiche occasionali. È responsabilità dell'utente consultare regolarmente i Termini per essere consapevole di tali modifiche. L'utilizzo continuativo del Server dopo la pubblicazione delle modifiche implica l'accettazione delle stesse.
<br></br><br></br><br></br>
Raccolta e Utilizzo dei Dati Personali
<br></br>
<br></br>
2.1 Il Server potrebbe richiedere la raccolta di alcuni dati personali dei giocatori, come l'indirizzo email o il nome utente. Questi dati saranno raccolti solo con il consenso degli utenti e saranno utilizzati esclusivamente per finalità di autenticazione, gestione degli account e comunicazioni legate al Server.
<br></br>
<br></br>
2.2 Tutti i dati personali raccolti saranno trattati nel rispetto della normativa sulla privacy vigente. Il Server si impegna a proteggere la privacy dei giocatori e ad adottare le misure necessarie per evitare l'accesso non autorizzato, la divulgazione o la modifica dei dati personali.
<br></br>
<br></br>
2.3 Il Server non condividerà i dati personali dei giocatori con terze parti senza il loro consenso esplicito, a meno che ciò non sia richiesto dalla legge o da un'ordine giudiziario.
<br></br><br></br><br></br>
Cookie e Tecnologie di Tracciamento
<br></br>
<br></br>
3.1 Il Server potrebbe utilizzare cookie e altre tecnologie di tracciamento per migliorare l'esperienza degli utenti e raccogliere informazioni anonime sull'utilizzo del Server. Questi dati verranno utilizzati solo per finalità statistiche e di analisi.
<br></br>
<br></br>
3.2 Gli utenti possono controllare le impostazioni dei propri browser per gestire i cookie e rifiutarli se lo desiderano. Tuttavia, ciò potrebbe limitare alcune funzionalità del Server.
<br></br><br></br><br></br>
Contenuti Generati dagli Utenti
<br></br><br></br>
4.1 Gli utenti del Server sono responsabili dei contenuti generati da loro stessi, come messaggi di chat o post nei forum. Essi devono rispettare le leggi applicabili e non devono violare i diritti di terzi o diffondere contenuti offensivi, illegali o ingannevoli.
<br></br><br></br>
4.2 Il Server si riserva il diritto di monitorare e moderare i contenuti generati dagli utenti al fine di garantire un ambiente di gioco sicuro e rispettoso.
<br></br><br></br><br></br>
Limitazioni di Responsabilità
<br></br><br></br>
5.1 Il Server non si assume alcuna responsabilità per eventuali danni diretti, indiretti, incidentali, speciali o consequenziali derivanti dall'utilizzo del Server o dall'incapacità di utilizzarlo.
<br></br><br></br>
5.2 Il Server non garantisce la disponibilità continua e priva di interruzioni del server.
<br></br>
              </h4>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-xl-10">
            <div className="banner-countdown-wrap text-center">
              



            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
