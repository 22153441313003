import React from "react";
import shape01 from "../../assets/img/images/faq_shape01.png";
import shape02 from "../../assets/img/images/faq_shape02.png";
import shape03 from "../../assets/img/images/faq_shape03.png";
import FaqItem from "./FaqItem";

const Faq = () => {
  const faq_items = [
    {
      id: "headingOne",
      controls: "collapseOne",
      expanded: false,
      btnClass: "collapsed",
      contentClass: "",
      title: "Come posso unirmi alla community ed accedere al server?",
      details: `Per iniziare a giocare nel server dovrai superare la whitelist. Non ti preoccupare, non e' niente di impegnativo! Ti bastera' aprire un ticket background e inserire la storia del tuo personaggio. Per permetterti di scriverlo correttamente, ti consigliamo di leggere attentamente le linee guida del server. Una volta che un membro dello Staff avra' approvato il tuo background verrai chiamato per il colloquio conoscitivo basato sulla tua conoscenza del regolamento di gioco. Ti ricordiamo che lo staff e' a tua disposizione per qualsiasi chiarimento.`,
    },
    {
      id: "headingTwo",
      controls: "collapseTwo",
      expanded: false,
      btnClass: "collapsed",
      contentClass: "",
      title: "Per giocare nel server necessito di qualcosa?",
      details: `Per giocare all'interno del server sarà necessario possedere una copia di Red Dead Redemption 2 o la versione Online di Red Dead Redemption. Una volta che avrai installato il gioco dovrai scaricare il launcher di RedM scaricabile dall'apposito sito: https://redm.net/  e  Teamspeak 3 (trovi la guida dettagliata sul nostro sito web [Link Utili>Guida SaltyChat])`,
    },
    {
      id: "headingThree",
      controls: "collapseThree",
      expanded: false,
      btnClass: "collapsed",
      contentClass: "",
      title: "Posso giocare su console?",
      details: `No. Purtroppo per giocare hai bisogno di RedM che funziona solo su PC.`,
    },
    {
      id: "headingFour",
      controls: "collapseFour",
      expanded: false,
      btnClass: "collapsed",
      contentClass: "",
      title: "Quali sono i requisiti di sistema consigliati per avere una buona esperienza?",
      details: `Sistema operativo: Windows 10 <br></br>
      CPU: Intel Core i7-4770K / AMD Ryzen 5 1500X
      RAM: 12 GB
      Scheda grafica: Nvidia GeForce GTX 1060 6GB / AMD Radeon RX 480 4GB
      Spazio di archiviazione: 150 GB
      Memoria video dedicata: 4 - 6 GB`,
    },
    {
      id: "headingFive",
      controls: "collapseFive",
      expanded: false,
      btnClass: "collapsed",
      contentClass: "",
      title: "Quali sono le regole in Western Legends?",
      details: `Le regole del nostro server  sono progettate per garantire un'esperienza di gioco equa e divertente per tutti i partecipanti. Puoi trovare una lista completa delle regole sul nostro sito web nella sezione dedicata alle regole del server (Link Utili>Regolamento).`,
    },
  ];

  return (
    <section id="faq" className="faq-area">
      <div className="container custom-container-four">
        <div className="faq-shape-wrap">

        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="section-title section-title-two text-center mb-60">
              <h2 className="title">FAQ</h2>
            </div>

            <div className="faq-wrap wow fadeInUp" data-wow-delay=".2s">
              <div className="accordion" id="accordionExample">
                {faq_items.map((x, index) => (
                  <FaqItem key={index} item={x} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
