import React from "react";


const Banner = (props) => {
  return (
    <section className="banner-areas banner-bgs">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="banner-content text-center">
              <h1 className="title">
                Installazione di <span>SaltyChat</span>
              </h1>
            </div>
            <div className="banner-progress-wrap">
              

              <h4 className="titler">
              Il plugin SaltyChat per Teamspeak viene utilizzato per le comunicazioni in gioco. Per installarlo correttamente, seguire i passaggi seguenti:
              <br></br> <br></br>
              <span>1.</span> Installate Teamspeak e successivamente chiudetelo.
              Download: <a href="https://teamspeak.com/en/downloads/">Teamspeak 3</a>
              <br></br> <br></br><span>2.</span> Fare click per scaricare la versione 3.1.2 di SaltyChat.
              Download : <a href="https://cdn.discordapp.com/attachments/715177746438946876/1155926240268668928/SaltyChat_3.1.2_1.ts3_plugin">SaltyChat 3.1.2</a>
              <br></br> <br></br>
              <span>3.</span> Aprire TeamSpeak ma <span>NON</span> aggiornate SaltyChat. La chat vocale di prossimità funziona solo con la versione 3.1.2
              <br></br> <br></br>
              <span>4.</span> Attivate il push to talk andando su Strumenti -- Cattura -- Dispositivo di cattura (Impostare il microfono) -- Push-To-Talk
              Dovrete assegnare un tasto di attivazione del microfono che utilizzerete per parlare in gioco
              <img src="https://cdn.discordapp.com/attachments/715177746438946876/1068983761133391932/Capture.png" />
              <br></br> <br></br>
              <span>5.</span> Opzionale ma altamente consigliato: è possibile disattivare le notifiche sonore andando su Self -- Soundpack -- Sounds Deactivated.
              <img src="https://cdn.discordapp.com/attachments/715177746438946876/1068983761426985070/Sound_Pack.png" />
              <br></br> <br></br><br></br>
              IP di Teamspeak: <span>ts.westernlegends.it</span>
              <br></br>
              IP del server RedM: <span>play.westernlegends.it</span>
              <br></br><br></br>
              Risoluzione problemi SaltyChat
              <br></br><br></br>
<span>1.</span> SE SALTYCHAT NON VI SPOSTA, FATE IL CAMBIO DEI DNS
<br></br><br></br>
- Primario 8.8.8.8 oppure 1.1.1.1
<br></br>
- Secondario 8.8.4.4 oppure 1.0.0.1
<br></br><br></br>
<span>2.</span> Se non vi sposta, provate ad accettare i termini e le condizioni
<br></br><br></br>
Puoi connetterti al nostro server RedM cercandoci nel browser dei server RedM, oppure premendo F8 sulla tastiera e digitando:  <span> connect play.westernlegends.it</span>
<br></br><br></br>
<h3>IMPORTANTE</h3>

Una volta in gioco, assicuratevi di andare nelle impostazioni e di disabilitare la chat vocale, altrimenti potreste sentire persone che non dovreste sentire!
              </h4>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-xl-10">
            <div className="banner-countdown-wrap text-center">
              



            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
