import React, { useEffect, useState } from "react";
import SlickSlider from "../SlickSlider/SlickSlider";

import WhyChooseUsItem from "./WhyChooseUsItem";

const WhyChooseUs = () => {
  const slickSettings = {
    dots: false,
    infinite: false,
    speed: 1,
    autoplay: false,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 0,
    responsive: [
      {
        breakpoint: 1,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const slider_items = [
    {
      alt: "",
      link: "/",
      title: "La nostra comunity",
      description: "La nostra community e' uno dei nostri punti di forza. All'interno si trovano tante persone, ognuna con uno scopo comune: quello di fare roleplay di qualita'. Riescono in maniera strepitosa a unire rp e divertimento, condividendo insegnamenti ogni giorno.ㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤ",
    },
    {
      alt: "",
      link: "/",
      title: "Lo sviluppo del server",
      description: "Il server è stato ideato e creato da  developer professionista che svolge il lavoro unendo due ideali importantissimi: Script innovativi e fluidità che rendono il server accessibile a tutti con meccaniche mai viste.ㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤ",
    },
    {
      alt: "",
      link: "/",
      title: <>Ambientazione dettagliata</>,
      description: "Il nostro server offre un'ambientazione dettagliata, permettendo agli utenti di immergersi completamente nell'atmosfera del vecchio WEST. Dalle praterie aperte alle cittadine polverose, i giocatori avranno l'opportunità di vivere avventure autentiche in un'ambientazione unica! ",
    },
    {
      alt: "",
      link: "/",
      title: "Un sistema di gioco ben strutturato",
      description: "Un buon server RP richiede un sistema di gioco solido e ben strutturato. Tutto il server è stato bilanciato e strutturato per cercare di rendere il gioco il più verosimile possibile al contesto storico.  Questo vi permetterà di poter sviluppare una propria storia con tanti vari sviluppi!",
    }
  ];

  const initalState = 0;
  const [count, setCount] = useState(initalState);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((old) => (old == 6 ? 0 : old + 1));
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const toMultiply = 100 / slider_items.length;

  return (
    <section id="pkn" className="choose-area pb-130">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6">
            <div className="section-title text-center mb-50">
              <span className="sub-title">Perchè scegliere noi </span>
              <h2 className="title">
                Perchè scegliere <span>Western Legends</span>
              </h2>
            </div>
          </div>
        </div>

        <div className="row choose-active">
        <SlickSlider settings={slickSettings}>
            {slider_items.map((item, index) => (
              <div key={index} className="col-lg-3">
                <WhyChooseUsItem item={item} />
              </div>
            ))}
          </SlickSlider>
        </div>


        {/* <div
          className="slide-progress"
          role="progressbar"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <span className="slider__label sr-only"></span>
        </div> */}
      </div>
    </section>
  );
};

export default WhyChooseUs;
