import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import { useEffect } from "react";
import WOW from "wow.js";
import Donazioni from "./pages/Donazioni/Donazioni";
import Mappas from "./pages/Donazioni/Mappa";
import Mappa from "./pages/Donazioni/MappaCase";
import MappaDiGioco from "./pages/Link Utili/map";
import TeC from "./pages/TeC/tec";
import Saltychat from "./pages/Guide/saltychat";
import IP from "./pages/Link Utili/ip";
import Errore from "./pages/404/errore";
import { useLocation } from "react-router-dom";



function App() {
  useEffect(() => {
    const wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: false,
      live: true,
    });
    wow.init();
  }, []);


  
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);


  return (
    <>
    
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="donazioni" element={<Donazioni />} />
        <Route path="mappa/ranch" element={<Mappas />} />
        <Route path="mappa/case" element={<Mappa />} />
        <Route path="guida/saltychat" element={<Saltychat />} />
        <Route path="ip" element={<IP />} />
        <Route path="termini&condizioni" element={<TeC />} />
        <Route path="mappa" element={<MappaDiGioco />} />
        <Route path="*" element={<Errore />} />
      </Routes>
    </>
  );
}
export default App;
