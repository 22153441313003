import React, { useState, Fragment } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import mappa from "../../assets/img/donazioni/mappers.png"
import aperto from "../../assets/img/donazioni/free.png"
import chiuso from "../../assets/img/donazioni/block.png"
import Ranch1Gif from "../../assets/img/donazioni/Ranch1.gif"
import Ranch2Gif from "../../assets/img/donazioni/Ranch2.gif"
import Ranch3Gif from "../../assets/img/donazioni/Ranch3.gif"
import Ranch4Gif from "../../assets/img/donazioni/Ranch4.gif"
import Ranch5Gif from "../../assets/img/donazioni/Ranch5.gif"
import Ranch6Gif from "../../assets/img/donazioni/Ranch6.gif"
import Ranch7Gif from "../../assets/img/donazioni/Ranch7.gif"
import Ranch8Gif from "../../assets/img/donazioni/Ranch8.gif"
import Ranch9Gif from "../../assets/img/donazioni/Ranch9.gif"
import Ranch10Gif from "../../assets/img/donazioni/Ranch10.gif"
import Ranch11Gif from "../../assets/img/donazioni/Ranch11.gif"
import Ranch12Gif from "../../assets/img/donazioni/Ranch12.gif"
import Ranch13Gif from "../../assets/img/donazioni/Ranch13.gif"
import Ranch14Gif from "../../assets/img/donazioni/Ranch14.gif"
import Ranch15Gif from "../../assets/img/donazioni/Ranch15.gif"
import Ranch16Gif from "../../assets/img/donazioni/Ranch16.gif"
import Ranch17Gif from "../../assets/img/donazioni/Ranch17.gif"
import Ranch18Gif from "../../assets/img/donazioni/Ranch18.gif"
import Ranch19Gif from "../../assets/img/donazioni/Ranch19.gif"
import Ranch20Gif from "../../assets/img/donazioni/Ranch20.gif"
import Ranch21Gif from "../../assets/img/donazioni/Ranch21.gif"
import Ranch22Gif from "../../assets/img/donazioni/Ranch22.gif"
import Ranch23Gif from "../../assets/img/donazioni/Ranch23.jpg"

import imgmap from "../../assets/img/icon/logospendente.gif"
import Swal from 'sweetalert2'
import { Link, NavLink, useLocation } from "react-router-dom";



function Mappa() {
  // POSIZIONI RANCH, DA CAMBIARE NOME E POSIZIONE PER OGNI RANCH

  const uno = {
    left: '430px',
    top: "380px",
    position: "relative",
  };

  const due = {
    left: '460px',
    top: "350px",
    position: "relative",
  };
  
  const tre = {
    left: '1200px',
    top: "495px",
    position: "relative",
  };
  
  const quattro = {
    left: '620px',
    top: "415px",
    position: "relative",
  };
  
  const cinque = {
    left: '925px',
    top: "395px",
    position: "relative",
  };

  const sei = {
    left: '580px',
    top: "480px",
    position: "relative",
  };

  const sette = {
    left: '852px',
    top: "655px",
    position: "relative",
  };

  const otto = {
    left: '380px',
    top: "45px",
    position: "relative",
  };

  const nove = {
    left: '475px',
    top: "-10px",
    position: "relative",
  };

  const dieci = {
    left: '780px',
    top: "620px",
    position: "relative",
  };
  
  const undici = {
    left: '720px',
    top: "550px",
    position: "relative",
  };

  const dodici = {
    left: '40px',
    top: "890px",
    position: "relative",
  };

  const tredici = {
    left: '375px',
    top: "360px",
    position: "relative",
  };

  const quattordici = {
    left: '590px',
    top: "505px",
    position: "relative",
  };

  const quindici = {
    left: '267px',
    top: "367px",
    position: "relative",
  };

  const sedici = {
    left: '230px',
    top: "165px",
    position: "relative",
  };

  const diciasette = {
    left: '-20px',
    top: "710px",
    position: "relative",
  };

  const diciotto = {
    left: '362px',
    top: "323px",
    position: "relative",
  };

  const dicianove = {
    left: '500px',
    top: "490px",
    position: "relative",
  };

  const venti = {
    left: '-200px',
    top: "760px",
    position: "relative",
  };

  const ventuno = {
    left: '145px',
    top: "300px",
    position: "relative",
  };

  const ventidue = {
    left: '325px',
    top: "750px",
    position: "relative",
  };

  const ventitre = {
    left: '395px',
    top: "625px",
    position: "relative",
  };

  const ventiquattro = {
    left: '151px',
    top: "428px",
    position: "relative",
  };

  const venticinque = {
    left: '265px',
    top: "385px",
    position: "relative",
  };

  const ventisei = {
    left: '439px',
    top: "87px",
    position: "relative",
  };

  // GIF RANCH, DA CAMBIARE NOME, LINK, TITOLO, TEXT PER OGNI RANCH

  const Gif1 = () =>{
    Swal.fire({
      imageUrl: Ranch1Gif,
      imageWidth: 600,
      imageHeight: 300,
      imageAlt: 'Custom image',
      title: 'Ranch Big Valley - (01)',
      background: 'rgba(255, 255, 255, 0.9)',
      text: 'Il Piu Grande Ranch Grande situato in una delle zone migliori per cacciare, Cosi chiamata la Big Valley.',
      padding: '0px',
      margin: '0px'
    })
  }

  const Gif2 = () =>{
    Swal.fire({
      imageUrl: Ranch2Gif,
      imageWidth: 600,
      imageHeight: 300,
      imageAlt: 'Custom image',
      title: 'Ranch West Elizabeth 2 - (02)',
      background: 'rgba(255, 255, 255, 0.9)',
      text: 'Ranch Grande situato in una delle zone migliori per cacciare, vicino alla miniera ed a un fiume.',
      padding: '0px',
      margin: '0px'
    })
  }
  
  const Gif3 = () =>{
    Swal.fire({
      imageUrl: Ranch3Gif,
      imageWidth: 600,
      imageHeight: 300,
      imageAlt: 'Custom image',
      title: 'Ranch Kamassa River - (03)',
      background: 'rgba(255, 255, 255, 0.9)',
      text: 'Ranch vicino le paludi per chi vuole stare in pace, con fiumi e coccodrilli.',
      padding: '0px',
      margin: '0px'
    })
  }
  
  const Gif4 = () =>{
    Swal.fire({
      imageUrl: Ranch4Gif,
      imageWidth: 600,
      imageHeight: 300,
      imageAlt: 'Custom image',
      title: 'Ranch Valentine - (04)',
      background: 'rgba(255, 255, 255, 0.9)',
      text: 'Ranch situato vicino alla cittadina di Valentine ottimo per gli affari, con strade principali e via vai di persone',
      padding: '0px',
      margin: '0px'
    })
  }
  
  const Gif5 = () =>{
    Swal.fire({
      imageUrl: Ranch5Gif,
      imageWidth: 600,
      imageHeight: 300,
      imageAlt: 'Custom image',
      title: 'Ranch New Hanover - (05)',
      background: 'rgba(255, 255, 255, 0.9)',
      text: 'Ranch situato in una vasta pianura, in una zona strategica per un ottimo commercio tra le cittadine.',
      padding: '0px',
      margin: '0px'
    })
  }


  const Gif6 = () =>{
    Swal.fire({
      imageUrl: Ranch6Gif,
      imageWidth: 600,
      imageHeight: 300,
      imageAlt: 'Custom image',
      title: 'Ranch Dakota River - (06)',
      background: 'rgba(255, 255, 255, 0.9)',
      text: 'Ranch sopraelevato circondato da un bellissimo fiume tra Valentine e BlackWater',
      padding: '0px',
      margin: '0px'
    })
  }
  
  const Gif7 = () =>{
    Swal.fire({
      imageUrl: Ranch7Gif,
      imageWidth: 600,
      imageHeight: 300,
      imageAlt: 'Custom image',
      title: 'Ranch Rhodes - (07)',
      background: 'rgba(255, 255, 255, 0.9)',
      text: 'Ranch molto vasto con molta vegetazione con fonti acqua vicino con la cittadina di Rhodes',
      padding: '0px',
      margin: '0px'
    })
  }
  
  const Gif8 = () =>{
    Swal.fire({
      imageUrl: Ranch8Gif,
      imageWidth: 600,
      imageHeight: 300,
      imageAlt: 'Custom image',
      title: 'Ranch di Ambarino - (08)',
      background: 'rgba(255, 255, 255, 0.9)',
      text: 'Piu che Ranch, e proprio una cittadina innevata',
      padding: '0px',
      margin: '0px'
    })
  }

  const Gif9 = () =>{
    Swal.fire({
      imageUrl: Ranch9Gif,
      imageWidth: 600,
      imageHeight: 300,
      imageAlt: 'Custom image',
      title: 'Ranch di Ambarino Nord - (09)',
      background: 'rgba(255, 255, 255, 0.9)',
      text: 'Ranch isolato per una avventura in duo con una atmosfera incantevole.',
      padding: '0px',
      margin: '0px'
    })
  }

  const Gif10 = () =>{
    Swal.fire({
      imageUrl: Ranch10Gif,
      imageWidth: 600,
      imageHeight: 300,
      imageAlt: 'Custom image',
      title: 'Ranch Lemoyne - (10)',
      background: 'rgba(255, 255, 255, 0.9)',
      text: 'Ranch semplicemente perfetto commercio ottimo, vicino alla Palude che sfocia sul kamassa river.',
      padding: '0px',
      margin: '0px'
    })
  }

  const Gif11 = () =>{
    Swal.fire({
      imageUrl: Ranch11Gif,
      imageWidth: 600,
      imageHeight: 300,
      imageAlt: 'Custom image',
      title: 'Ranch Lemoyne Losco - (11)',
      background: 'rgba(255, 255, 255, 0.9)',
      text: 'Ranch a Lemoyne perfetto per avere un ranch per il commercio, ma anche perfetto per nascondersi con una casa abbondona',
      padding: '0px',
      margin: '0px'
    })
  }
  
  const Gif12 = () =>{
    Swal.fire({
      imageUrl: Ranch12Gif,
      imageWidth: 600,
      imageHeight: 300,
      imageAlt: 'Custom image',
      title: 'Macfarlanes Ranch - (12)',
      background: 'rgba(255, 255, 255, 0.9)',
      text: 'Il Ranch piu grande di tutte le terre del WestLakota.',
      padding: '0px',
      margin: '0px'
    })
  }
  const Gif13 = () =>{
    Swal.fire({
      imageUrl: Ranch13Gif,
      imageWidth: 600,
      imageHeight: 300,
      imageAlt: 'Custom image',
      title: 'Ranch Valentine - (13)',
      background: 'rgba(255, 255, 255, 0.9)',
      text: 'Unico, dentro la citta e inutile aggiungere altro.',
      padding: '0px',
      margin: '0px'
    })
  }
  
  const Gif14 = () =>{
    Swal.fire({
      imageUrl: Ranch14Gif,
      imageWidth: 600,
      imageHeight: 300,
      imageAlt: 'Custom image',
      title: 'Ranch Lemoyne - (14)',
      background: 'rgba(255, 255, 255, 0.9)',
      text: 'Ranch centrale nella mappa, molto buono per un amplio commercio, Casa senza interior per questa potrai scegliere una casa instanziata.',
      padding: '0px',
      margin: '0px'
    })
  }

  const Gif15 = () =>{
    Swal.fire({
      imageUrl: Ranch15Gif,
      imageWidth: 600,
      imageHeight: 300,
      imageAlt: 'Custom image',
      title: 'Ranch Valentine - (15)',
      background: 'rgba(255, 255, 255, 0.9)',
      text: 'Ranch appena fuori la cittadina di Valentine un giusto compromesso tra tranquillita e commodita, Casa senza interior per questa potrai scegliere una casa instanziata.',
      padding: '0px',
      margin: '0px'
    })
  }

  const Gif16 = () =>{
    Swal.fire({
      imageUrl: Ranch16Gif,
      imageWidth: 600,
      imageHeight: 300,
      imageAlt: 'Custom image',
      title: 'Ranch Window Rock - (16)',
      background: 'rgba(255, 255, 255, 0.9)',
      text: 'Ranch in disparte ma non troppo, in mezzo alla natura con un ambientazione da Film',
      padding: '0px',
      margin: '0px'
    })
  }

  const Gif17 = () =>{
    Swal.fire({
      imageUrl: Ranch17Gif,
      imageWidth: 600,
      imageHeight: 300,
      imageAlt: 'Custom image',
      title: 'Ranch Great Plains - (17)',
      background: 'rgba(255, 255, 255, 0.9)',
      text: 'Ranch di Arthur accanto alla cittadina di BlackWater, chi non vorrebbe questo Ranch?',
      padding: '0px',
      margin: '0px'
    })
  }

  const Gif18 = () =>{
    Swal.fire({
      imageUrl: Ranch18Gif,
      imageWidth: 600,
      imageHeight: 300,
      imageAlt: 'Custom image',
      title: 'Ranch New Hanover - (18)',
      background: 'rgba(255, 255, 255, 0.9)',
      text: 'Ranch vicino alla cittadina di Valentine con una casa a due piani, situato in mezzo alle colline del New Hanover',
      padding: '0px',
      margin: '0px'
    })
  }

  const Gif19 = () =>{
    Swal.fire({
      imageUrl: Ranch19Gif,
      imageWidth: 600,
      imageHeight: 300,
      imageAlt: 'Custom image',
      title: 'Ranch Lemoyne - (19)',
      background: 'rgba(255, 255, 255, 0.9)',
      text: 'Ranch con una bellissima villa di due piani con un perfetto commercio tra cittadine e con la sua bella dose di natura.',
      padding: '0px',
      margin: '0px'
    })
  }

  const Gif20 = () =>{
    Swal.fire({
      imageUrl: Ranch20Gif,
      imageWidth: 600,
      imageHeight: 300,
      imageAlt: 'Custom image',
      title: 'Ranch Manzanita Post - (20)',
      background: 'rgba(255, 255, 255, 0.9)',
      text: 'Ranch immerso nella natura perfetto per i cacciatori, si puo trovare qualsiasi specie animale anche uomo.',
      padding: '0px',
      margin: '0px'
    })
  }

  const Gif21 = () =>{
    Swal.fire({
      imageUrl: Ranch21Gif,
      imageWidth: 600,
      imageHeight: 300,
      imageAlt: 'Custom image',
      title: 'Ranch Cumberland Forest - (21)',
      background: 'rgba(255, 255, 255, 0.9)',
      text: 'Un Ranch situato sopra le colline della cittadina di Valentine, purtroppo un vecchio covo di banditi.',
      padding: '0px',
      margin: '0px'
    })
  }

  const Gif22 = () =>{
    Swal.fire({
      imageUrl: Ranch22Gif,
      imageWidth: 600,
      imageHeight: 300,
      imageAlt: 'Custom image',
      title: 'Ranch Rhodes - (22)',
      background: 'rgba(255, 255, 255, 0.9)',
      text: 'Un nuovo Ranch la casa e fresca e possibile arredarla, la piu vicina alla grande citta di Saint Denis',
      padding: '0px',
      margin: '0px'
    })
  }
  const Gif23 = () =>{
    Swal.fire({
      imageUrl: '',
      imageWidth: 600,
      imageHeight: 300,
      imageAlt: 'Custom image',
      title: 'Ranch Saint Denis - (23)',
      background: 'rgba(255, 255, 255, 0.9)',
      text: 'Grande Ranch sulle porte di Saint Denis in mezzo alla palude',
      padding: '0px',
      margin: '0px'
    })
  }
  const Gif24 = () =>{
    Swal.fire({
      imageUrl: '',
      imageWidth: 600,
      imageHeight: 300,
      imageAlt: 'Custom image',
      title: 'Ranch Centrale - (24)',
      background: 'rgba(255, 255, 255, 0.9)',
      text: 'Piccola Casa ma grande spazio posizionato completamente al centro mappa.',
      padding: '0px',
      margin: '0px'
    })
  }
  const Gif25 = () =>{
    Swal.fire({
      imageUrl: '',
      imageWidth: 600,
      imageHeight: 300,
      imageAlt: 'Custom image',
      title: 'Ranch Centrale - (25)',
      background: 'rgba(255, 255, 255, 0.9)',
      text: 'Casa arredabile con uno spazio enorme tutto recintato',
      padding: '0px',
      margin: '0px'
    })
  }
  const Gif26 = () =>{
    Swal.fire({
      imageUrl: Ranch23Gif,
      imageWidth: 600,
      imageHeight: 300,
      imageAlt: 'Custom image',
      title: 'Ranch Annesburg - (26)',
      background: 'rgba(255, 255, 255, 0.9)',
      text: 'Ranch vicino una cascata in cima alla mappa molto isolato e romantico',
      padding: '0px',
      margin: '0px'
    })
  }
  
  
  
  
  return (
    <Container fluid className="project-sectiona">
      <Container>
        <h1 className="project-headings"style={{ color: "black" }}>
          I <strong className="purples">Ranch</strong> Disponibili
        </h1>
        <p className="project-headings" style={{ color: "black" }}>
          Potrai Cliccare e Visionare ogni Ranch presente sulla Mappa.
        <p className="project-testo" style={{ color: "black" }}> 
          La Mappa potra essere visualizzata perfettamente solo su schermi a 16:9</p>
        </p>
        <li>
        
        <img style={uno} onClick={Gif1} variant="separate-button" src={chiuso} class="bottone-img"/>   
        <img style={due} onClick={Gif2} variant="separate-button" src={chiuso} class="bottone-img"/>
        <img style={tre} onClick={Gif3} variant="separate-button" src={chiuso} class="bottone-img"/>
        <img style={quattro} onClick={Gif4} variant="separate-button" src={chiuso} class="bottone-img"/>
        <img style={cinque} onClick={Gif5} variant="separate-button" src={chiuso} class="bottone-img"/>
        <img style={sei} onClick={Gif6} variant="separate-button" src={chiuso} class="bottone-img"/>
        <img style={sette} onClick={Gif7} variant="separate-button" src={chiuso} class="bottone-img"/>
        <img style={otto} onClick={Gif8} variant="separate-button" src={aperto} class="bottone-img"/>
        <img style={nove} onClick={Gif9} variant="separate-button" src={aperto} class="bottone-img"/>
        <img style={dieci} onClick={Gif10} variant="separate-button" src={chiuso} class="bottone-img"/>
        <img style={undici} onClick={Gif11} variant="separate-button" src={aperto} class="bottone-img"/>
        <img style={dodici} onClick={Gif12} variant="separate-button" src={chiuso} class="bottone-img"/>
        <img style={tredici} onClick={Gif13} variant="separate-button" src={aperto} class="bottone-img"/>
        <img style={quattordici} onClick={Gif14} variant="separate-button" src={chiuso} class="bottone-img"/>
        <img style={quindici} onClick={Gif15} variant="separate-button" src={aperto} class="bottone-img"/>
        <img style={sedici} onClick={Gif16} variant="separate-button" src={chiuso} class="bottone-img"/>
        <img style={diciasette} onClick={Gif17} variant="separate-button" src={chiuso} class="bottone-img"/>
        <img style={diciotto} onClick={Gif18} variant="separate-button" src={chiuso} class="bottone-img"/>
        <img style={dicianove} onClick={Gif19} variant="separate-button" src={chiuso} class="bottone-img"/>
        <img style={venti} onClick={Gif20} variant="separate-button" src={chiuso} class="bottone-img"/>
        <img style={ventuno} onClick={Gif21} variant="separate-button" src={chiuso} class="bottone-img"/>
        <img style={ventidue} onClick={Gif22} variant="separate-button" src={aperto} class="bottone-img"/>
        <img style={ventitre} onClick={Gif23} variant="separate-button" src={chiuso} class="bottone-img"/>
        <img style={ventiquattro} onClick={Gif24} variant="separate-button" src={chiuso} class="bottone-img"/>
        <img style={venticinque} onClick={Gif25} variant="separate-button" src={chiuso} class="bottone-img"/>
        <img style={ventisei} onClick={Gif26} variant="separate-button" src={aperto} class="bottone-img"/>


        </li>
        <li className={"btnmapparanch"}>
                      <Link to="https://westernlegends.it" className="btn">
                          Torna alla Home
                        </Link>
                      </li>
        </Container>
    </Container>
  );
}

export default Mappa;