import React from "react";
import img01 from "../../assets/img/logo/Logo4.png";
import { Link } from "react-router-dom";

const WhoWeAre = (props) => {
  return (
    <section id="about" className="about-area pt-130 pb-130">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-img wow fadeInLeft" data-wow-delay=".2s">
              <img src={img01} alt="" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-content wow fadeInRight" data-wow-delay=".2s">
              <div className="section-title mb-30">
                <span className="sub-title">Chi siamo</span>
                <h2 className="title">
                 Conosci <span>Western Legends</span>!
                </h2>
              </div>
              <p>
              Western Legends è un progetto creato il 12.04.2022 con lo scopo di portare il vero rp in Italia. L'idea è nata da un gruppo di amici che, stanchi dall'esperienza trovata in altri contesti, hanno deciso di creare questo progetto portando con se se l'ideale di un server formato da ottimi script, staffer competenti sempre disponibili e ottimo rp.  Il progetto attualmente sta continuando ad evolversi migliorando ogni giorno grazie alla sua grande comunity, nota per la sua tranquillità e per la continua opportunità di crescita, e grazie al lavoro del corpo staff, sempre attento e pronto ad ogni situazione. 
              </p>
              <Link to="https://docs.google.com/document/d/1HwBQQHRJLodWhTX7cdVIAAHr9W5lHpwXQuWw9E_IQiU/edit?usp=sharing" className="btn">
                Regolamento 4.0
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhoWeAre;
