import React from "react";



const Banner = (props) => {
  return (
    <section className="banner-area banner-bgr">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="banner-content text-center">
              <h2 className="titler">
                Indirizzo IP <span>Western Legends</span> (F8)
              </h2>
              <br></br>
              <h3 className="titlerr">connect play.westernlegends.it</h3>
<br></br><br></br>
<h2 className="titler">
                Indirizzo IP <span>TeamSpeak</span>
              </h2>
              <br></br>
              <h3 className="titlerr"> ts.westernlegends.it</h3>
            </div>
            <div className="banner-progress-wrap">
              

              <h4 className="title">
               
              </h4>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-xl-10">
            <div className="banner-countdown-wrap text-center">
              
            <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> 



            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
