import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Donazione1 from "../../assets/img/donazioni/Pack_Ranch.png";
import Donazione2 from "../../assets/img/donazioni/Pack_Casa.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Container>
        <h1 className="project-heading">
          I nostri pack <strong className="purple">Donazione </strong>
        </h1>
        <p style={{ color: "white" }}>
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Donazione1}
              isBlog={false}
              title="Pack Ranch"
              description="Qui puoi vedere tutti i Ranch presenti nel server e la loro disponibilita, ricordati di prendere il codice Ranch a due cifre per aiutare lo staff a identificarlo."
              ghLink="https://discord.gg/westernlegends"
              demoLink="/mappa/ranch"
              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Donazione2}
              isBlog={false}
              title="Pack Casa"
              description="Qui puoi vedere tutte le Casa presenti nel server e la loro disponibilita, ricordati di prendere il codice Casa a due cifre per aiutare lo staff a identificarlo."
              ghLink="https://discord.gg/westernlegends"
              demoLink="/mappa/case"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
