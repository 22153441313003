import React from "react";
import { Link } from "react-router-dom";


const Banner = (props) => {
  return (
    <section className="banner-area banner-bg">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="banner-content text-center">
              <h1 className="titles">
              <span>404</span>
              </h1>
            </div>
            <div className="banner-progress-wrap">
              

              <h4 className="titles">

             <> </>

             <br></br><br></br>
              ㅤㅤㅤㅤㅤㅤㅤOops! Si è verificato un errore ㅤ :( ㅤ pagina non è stata trovata.

              </h4>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-xl-10">
            <div className="banner-countdown-wrap text-center">
              



            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
