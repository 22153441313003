import React from "react";
import Donazionis from "../../components/Donazioni/donazioni";
import LayoutDone from "../../layouts/LayoutDone";
import CookieBanner from "../CookieBanner"; // new
import posthog from 'posthog-js' // new
const Donazioni = () => {
  return (
    <LayoutDone>
      {posthog.has_opted_out_capturing() // new
      ||posthog.has_opted_in_capturing() 
        ? null 
        : <CookieBanner /> 
      }
      <main className="fix">
    <Donazionis />
      </main>
    </LayoutDone>
  );
};

export default Donazioni;
