import React from "react";
import Banner from "../../components/TeC/Banner";
import LayoutDone from "../../layouts/LayoutIni";
import CookieBanner from "../CookieBanner"; // new
import posthog from 'posthog-js' // new
const Tec = () => {
  return (
    <LayoutDone>
                        {posthog.has_opted_out_capturing() // new
      ||posthog.has_opted_in_capturing() 
        ? null 
        : <CookieBanner /> 
      }
      <main className="fix">
    <Banner />
      </main>
    </LayoutDone>
  );
};

export default Tec;
