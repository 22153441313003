import React from "react";
import Mappas from "../../components/Donazioni/mappa";
import CookieBanner from "../CookieBanner"; // new
import posthog from 'posthog-js' // new

const Mappa = () => {
  return (
    
      <main className="fix">
      {posthog.has_opted_out_capturing() // new
      ||posthog.has_opted_in_capturing() 
        ? null 
        : <CookieBanner /> 
      }
    <Mappas />
      </main>
  );
};

export default Mappa;
