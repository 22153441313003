import React from "react";
import Banner from "../../components/Banner/Banner";
import ContactOne from "../../components/Contact/ContactOne";
import TeamOne from "../../components/Team/TeamOne";
import WhoWeAre from "../../components/WhoWeAre/WhoWeAre";
import WhyChooseUs from "../../components/WhyChooseUs/WhyChooseUs";
import LayoutOne from "../../layouts/LayoutOne";
import Faq from "../../components/Faq/Faq";
import Counter from "../../components/Counter/CounterArea/CounterArea";

import CookieBanner from "../CookieBanner"; // new
import posthog from 'posthog-js' // new

const Home = () => {
  
  return (
    <LayoutOne>
                  {posthog.has_opted_out_capturing() // new
      ||posthog.has_opted_in_capturing() 
        ? null 
        : <CookieBanner /> 
      }
      <main className="fix">
        <Banner />

        
        <WhoWeAre />


        <WhyChooseUs />


        <div className="area-bg">
        <Counter />
          <Faq />
        </div>

        <TeamOne />
        <ContactOne />
      </main>
    </LayoutOne>
  );
};

export default Home;
